import { api, API_ENDPOINT_STREAM, getToken } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ChatAPI = {
  newSession: async function (modelType, title, cancel = false) {
    try {
      const response = await api.request({
        url: `/${modelType === "text" ? "chat" : "image"}/new`,
        method: "POST",
        data: {
          title: title
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  getHistory: async function (modelType, cancel = false) {
    try {
      const response = await api.request({
        url: `/${modelType === "text" ? "chat" : "image"}/sessions`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  getSession: async function (sessionId, cancel = false) {
    try {
      const response = await api.request({
        url: `/session/history/${sessionId}`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  insertPrompt: async function (sessionId, role, prompt, template, temperature, topP, topK, maxTokens, stopSequences, fileInfo, ragInfo, internetSearch, cancel = false) {
    try {
      const response = await api.request({
        url: `/chat/insert/${sessionId}`,
        method: "POST",
        data: {
          prompt: prompt,
          role: role,
          use_case_template: template,
          temperature: temperature,
          top_p: topP,
          top_k: topK,
          max_tokens_to_sample: maxTokens,
          stop_sequences: stopSequences,
          files: fileInfo ? [fileInfo] : [],
          rag: ragInfo,
          tools: internetSearch ? [
            { "tool_name": "internet_search", "args": {"num_pages": 5} },
            // { "tool_name": "link_reader"}
          ] : [],
          model: "anthropic.claude-3-5-sonnet-20240620-v1:0"
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  prompt: async function (sessionId, prompt, template, temperature, topP, topK, maxTokens, stopSequences, fileInfo, ragInfo, internetSearch, cancel = false) {

    const request = {
      url: `/chat/${sessionId}`,
      method: "POST",
      // responseType: 'stream', // <-- this throws a warning, unsupported by axios and is ignored
      data: {
        prompt: prompt,
        use_case_template: template,
        temperature: temperature,
        top_p: topP,
        top_k: topK,
        max_tokens_to_sample: maxTokens,
        stop_sequences: stopSequences,
        files: fileInfo ? [fileInfo] : [],
        rag: ragInfo,
        tools: internetSearch ? [
          { "tool_name": "internet_search", "args": {"num_pages": 5} },
          // { "tool_name": "link_reader"}
        ] : [],
        model: "anthropic.claude-3-5-sonnet-20240620-v1:0"
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    };

    // if (file) {
    //   request.headers({'Content-Type', 'multipart/form-data'});
    // }


    try {
      const response = await api.request(request);
      return response;
    } catch (error) {
      throw error;
    }
  },

  promptStream: async function (sessionId, prompt, template, temperature, topP, topK, maxTokens, stopSequences, /*fileInfo, */ ragInfo, internetSearch, cancel = false, callback) {
    try {
      // Create WebSocket connection.
      const ws = new WebSocket(API_ENDPOINT_STREAM);

      // // Connection opened
      ws.addEventListener("open", (event) => {
        console.log("ws open: " + event);
        ws.send(JSON.stringify({
              authorization: getToken(),
              sessionId: sessionId,
              prompt: prompt,
              use_case_template: template,
              temperature: temperature,
              top_p: topP,
              top_k: topK,
              max_tokens_to_sample: maxTokens,
              stop_sequences: stopSequences,
              // files: fileInfo ? [fileInfo] : [],
              rag: ragInfo,
              tools: internetSearch ? [
                { "tool_name": "internet_search", "args": {"num_pages": 5} },
                // { "tool_name": "link_reader"}
              ] : [],
              model: "anthropic.claude-3-5-sonnet-20240620-v1:0"
            }));
      });

      // Listen for messages
      ws.addEventListener("message", callback);
      return ws;

    } catch (error) {
      throw error;
    }
  },

  fileUpload: async function (sessionId, fileInfo, cancel = false) {
    try {

      const response = await api.request({
        url: `/files/doc/${sessionId}`,
        method: "POST",
        data: fileInfo,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  queryDbUseCase: async function (sessionId, query, cancel = false) {
    /*
      query_name: str
      summarize_result: Optional[bool] = False
      model: Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
      params: Optional[Dict] = {}
    */
    try {
      const response = await api.request({
        url: `/query/use_case/${sessionId}`,
        method: "POST",
        data: query,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  queryDbAdHoc: async function (sessionId, query, cancel = false) {
    /*
      model: Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
      prompt: str
      ... also need 'collection' or some other way to associate w/ specific data & security model
    */
    try {
      const response = await api.request({
        url: `/query/ad_hoc/${sessionId}`,
        method: "POST",
        data: query,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  }

};

export const ImageAPI = {
  generate: async function (sessionId, prompt, negativePrompts, height, width, cfgScale, clipGuidancePreset, sampler, samples, seed, steps, stylePreset, aspectRatio, imageStrength, fileInfo, cancel = false) {
    try {
      const response = await api.request({
        url: `/image/${sessionId}`,
        method: "POST",
        data: {
          prompt: prompt,
          negative_prompts: negativePrompts,
          height: height,
          width: width,
          cfg_scale: cfgScale,
          clip_guidance_preset: clipGuidancePreset,
          sampler: sampler,
          samples: samples,
          seed: seed,
          steps: steps,
          style_preset: stylePreset || null,
          image: fileInfo,
          aspect_ratio: aspectRatio || "1:1",
          image_strength: imageStrength || 0.35,
          model: "stability.sd3-large-v1:0"
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      // returning the data returned by the API
      return response;

    } catch (error) {
      throw error;
    }
  },

  getImage: async function (sessionId, imageId, cancel = false) {
    try {
      const response = await api.request({
        url: `/image/${sessionId}/${imageId}`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
};

export const SessionHistoryAPI = {
  deleteSession: async function (sessionId, cancel = false) {
    try {
      const response = await api.request({
        url: `/session/delete/${sessionId}`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  restoreSession: async function (sessionId, cancel = false) {
    try {
      const response = await api.request({
        url: `/session/restore/${sessionId}`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  setSessionTitle: async function (sessionId, title, cancel = false) {
    try {
      const response = await api.request({
        url: `/session/title/${sessionId}`,
        method: "POST",
        data: {
          title: title
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  setSessionDescription: async function (sessionId, description, cancel = false) {
    try {
      const response = await api.request({
        url: `/session/description/${sessionId}`,
        method: "POST",
        data: {
          description: description
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  /** 
   * @param {string} sessionId
   * @param {string} responseId
   * @param {integer} feedback: -1, 0, 1 for thumbs down, neutral (remove existing feedback), thumbs up
   * @param {boolean} cancel
   * @returns {Promise}
   * */
  setResponseFeedback: async function (sessionId, responseId, feedback, cancel = false) {
    try {
      const response = await api.request({
        url: `/session/feedback/${sessionId}/${responseId}`,
        method: "POST",
        data: {
          feedback: feedback
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  }

};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ChatAPI)