import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UseCases from './UseCaseLibrary/UseCases';
import ChatMessage from './Common/ChatMessage';
import { ChatContext } from '../context/chatContext';
import { SessionContext } from '../context/sessionContext';
import { TextConfigContext } from '../context/textConfigContext';
import { DefaultTextConfig } from './ModelConfig/DefaultTextConfig';
import { ImageConfigContext } from '../context/imageConfigContext';
import { DefaultImageConfig } from './ModelConfig/DefaultImageConfig';
import { MdOutlineAttachFile, MdClose } from "react-icons/md";
import { FaPlus, FaStop, FaRegBookmark, FaArrowRight, FaAccessibleIcon } from "react-icons/fa";
import SavePromptDesktop from './Landing/SavePromptDesktop';
import SavePromptMobile from './Landing/SavePromptMobile';
import ChatFormMobile from './Common/Mobile/ChatFormMobile';
import ChatFormDesktop from './Common/Desktop/ChatFormDesktop';

import tablemark from 'tablemark';
import { Tooltip } from 'react-tooltip'

import Thinking from './Thinking';
import Filter from 'bad-words';
import { ChatAPI, ImageAPI } from '../api/chatAPI';
import GetStarted from './GetStarted';
import SelectPromptDesktop from './Landing/SelectPromptDesktop';
import SelectPromptMobile from './Landing/SelectPromptMobile';
import Prompt from './Landing/Prompt';
import { FavoritesAPI } from '../api/favoritesAPI';
import SideBarMobile from './Common/Mobile/SideBarMobile';
import { isMobile } from 'react-device-detect';
import HeaderMobile from './Common/Mobile/HeaderMobile';

const EmptyDiv = (props) => {
  return <div></div>;
};

function parseResponseToPrompts(response) {
  /*
    this function takes the JSON Response and parses 
    all the name, prompt, promptID tuples to a prompt object array
  */
  return response.map((item) => {
    return new Prompt(item.name, item.prompt, item.id);
  });
}

/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */

const ChatView = (props) => {
  const messagesEndRef = useRef();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const [thinking, setThinking] = useState(false);
  const [messages, addMessage, streamMessage, clearMessages, setStreamedMessageStopInfo] = useContext(ChatContext);
  const [, setImgSessionId, , setTxtSessionId, imgSessionTitle, , txtSessionTitle] = useContext(SessionContext);
  const [temperature, setTemperature, topP, setTopP, topK, setTopK, maxTokens, setMaxtokens, stopSequences, setStopSequences, template, setTemplate, ragInfo, setRagInfo, /*queryInfo*/, setQueryInfo, isBingSearchEnabled, setIsBingSearchEnabled] = useContext(TextConfigContext);
  const [negativePrompts, setNegativePrompts, height, setHeight, width, setWidth, cfgScale, setCfgScale, clipGuidancePreset, setClipGuidancePreset, sampler, setSampler, samples, /*setSamples*/, seed, setSeed, steps, setSteps, stylePreset, setStylePreset, aspectRatio, setAspectRatio, imageStrength, setImageStrength] = useContext(ImageConfigContext);
  const params = useParams();
  const navigate = useNavigate();
  const [messageIndex, setMessageIndex] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const [selectedFileInfo, setSelectedFileInfo] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);

  const fileInputRef = useRef(null);

  // These are not used.. 
  // const [currPromptSelected, setCurrPromptSelected] = useState(""); // get the prompt that is currently selected
  const [saveChatMessage, setSaveChatMessage] = useState("");

  const [initialOptions, setInitialOptions] = useState([]);
  const [currentStream, setCurrentStream] = useState(null);

  const [messageToResend, setMessageToResend] = useState(null);


  const fileTypes = {
    "text": "image/*,.pdf,.xps,.epub,.mobi,.fb2,.cbz,.svg,.docx,.txt,.csv,.json",
    "image": "image/*"
  }
  const [dropdownEnter, setDropdownEnter] = useState(false);
  const [savePromptEnter, setSavePromptEnter] = useState(false);
  const handleEnterDropdown = async (e) => {
    e.preventDefault();
    try {
      const response = await FavoritesAPI.getFavorites();
      const arr = parseResponseToPrompts(response.data);
      setInitialOptions(arr);
      setDropdownEnter(true);
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setDropdownEnter(false);
    }
  };


  const useCase = params.useCaseId ? UseCases.find(useCase => useCase.modelType === props.modelType && useCase.key === params.useCaseId) : null;

  // Is there a params component to inject associated with the use case?
  const UseCaseParams = (useCase?.reportParamsModal || EmptyDiv);

  // useCallback is used to memoize the function so that it is not recreated on every render, necessary because setConfig is a dependency of useEffect
  const setConfig = useCallback((modelType, config, template, ragInfo, queryInfo) => {
    // config can come from useCase definition, or from session history, and have slightly different keys for some values
    if (modelType === 'text') {
      setTemperature(config.temperature || DefaultTextConfig.temperature);
      setTopP(config.topP || config.top_p || DefaultTextConfig.top_p);
      setTopK(config.topK || config.top_k || DefaultTextConfig.top_k);
      setMaxtokens(config.maxTokens || config.max_tokens_to_sample || DefaultTextConfig.max_tokens_to_sample);
      setStopSequences(config.stopSequences || config.stop_sequences || DefaultTextConfig.stop_sequences);
      setTemplate(template || "");
      setRagInfo(ragInfo || null);
      setQueryInfo(queryInfo || null);
    }
    if (modelType === 'image') {
      setNegativePrompts(config.negativePrompts || config.negative_prompts || DefaultImageConfig.negativePrompts);
      setHeight(config.height || DefaultImageConfig.height);
      setWidth(config.width || DefaultImageConfig.width);
      setCfgScale(config.cfgScale || config.cfg_scale || DefaultImageConfig.cfg_scale);
      setClipGuidancePreset(config.clipGuidancePreset || config.clip_guidance_preset || DefaultImageConfig.clipGuidancePreset);
      setSampler(config.sampler || DefaultImageConfig.sampler);
      setSeed(config.seed || DefaultImageConfig.seed);
      setSteps(config.steps || DefaultImageConfig.steps);
      setStylePreset(config.stylePreset || config.style_preset || DefaultImageConfig.stylePreset);
      setAspectRatio(config.aspectRatio || DefaultImageConfig.aspectRatio);
      setImageStrength(config.imageStrength || DefaultImageConfig.imageStrength);
    }
  }, [setTemperature, setTopP, setTopK, setMaxtokens, setStopSequences, setNegativePrompts, setHeight, setWidth, setCfgScale, setClipGuidancePreset, setSampler, setSeed, setSteps, setStylePreset, setAspectRatio, setImageStrength, setTemplate, setRagInfo, setQueryInfo]);

  // set configuration values associated with use cases, if applicable
  useEffect(() => {
    if (useCase?.config || useCase?.template || useCase?.rag || useCase?.dbQuery) {
      const ragConfig = useCase.rag && (useCase.rag[process.env.REACT_APP_ENVIRONMENT] || useCase.rag);
      setConfig(props.modelType, useCase.config, useCase.template, ragConfig, useCase.dbQuery);
    }
  }, [setConfig, props.modelType, useCase, setTemperature, setTopP, setTopK, setMaxtokens, setStopSequences, setNegativePrompts, setHeight, setWidth, setCfgScale, setClipGuidancePreset, setSampler, setSeed, setSteps, setStylePreset, setAspectRatio, setImageStrength, setTemplate]);

  // auto-resize the text area
  useEffect(() => {
    inputRef.current.style.height = "auto";
    inputRef.current.style.height = inputRef.current.scrollHeight + "px";
  }, [formValue]);

  /**
   * Scrolls the chat area to the bottom.
   */
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const createNewSession = async (newMsg) => {
    // generate a new session id
    setWaiting(true);
    setCreatingSession(true);
    const response = await ChatAPI.newSession(props.modelType, newMsg);
    let newSession = response.data.session_id;
    props.modelType === 'image' ? setImgSessionId(newSession) : setTxtSessionId(newSession);
    navigate(`/${props.modelType}${params.useCaseId ? "/usecase/" + params.useCaseId : ""}/${newSession}`, { replace: true });
    setWaiting(false);
    setCreatingSession(false);
    return newSession;
  }

  function selectFile() {
    fileInputRef.current.click();
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];

    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const base64 = fileReader.result;
      setSelectedFileInfo({ "file_name": file.name, "file_data": base64.split(',')[1] });

      console.log({ "file_name": file.name, "file_data": base64.split(',')[1] })
    };

    fileReader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    // ChatAPI.fileUpload('session-id', event.target.files[0]);
  }

  const clearSelectedFile = () => {
    setSelectedFileInfo(null);
    fileInputRef.current.value = null;
  }

  // Try parsing error message from various exceptions / errors
  const parseError = (error) => {
    return `${error.message}: ${error.response?.data?.detail?.error || error.response?.data?.message || JSON.stringify(error?.response || error).substring(0, 500) + '...'}`;
  };

  /**
   * Adds a new message to the chat.
   *
   * @param {string} newValue - The text of the new message.
   * @param {boolean} [ai=false] - Whether the message was sent by an AI or the user.
   */
  const updateMessage = useCallback((newValue, sessionId, ai = false, turnType, error = false, existingMsg = null) => {
    const id = existingMsg?.id || (Date.now() + Math.floor(Math.random() * 1000000));
    const newMsg = {
      id: id,
      createdAt: Date.now(),
      text: newValue,
      ai: ai,
      modelType: props.modelType,
      turnType: turnType || "text",
      feedback: existingMsg?.kwargs?.feedback || 0,
      input_tokens: existingMsg?.input_tokens || 0,
      output_tokens: existingMsg?.output_tokens || 0,
      total_tokens: existingMsg?.total_tokens || 0,
      sessionId: sessionId,
      error: error,
    };

    addMessage(newMsg);
  }, [addMessage, props.modelType]);

  const cancelResponse = async () => {
    console.log('cancel response ');
    const stream = (process.env.REACT_APP_STREAM_RESPONSE || "").toLowerCase() === 'true';
    try {
      if (stream) {
        // cancels the API request and the UI response
        if (currentStream) {
          currentStream.close();
          let currentMessages = messages.filter(message => message.sessionId === params.sessionId);
          let lastMessage = currentMessages[currentMessages.length - 1];
          if (lastMessage.ai && lastMessage.text !== '...') {
            ChatAPI.insertPrompt(params.sessionId, "assistant", lastMessage.text, template, temperature, topP, topK, maxTokens, stopSequences, selectedFileInfo, ragInfo, isBingSearchEnabled, false);
          }
        }
        setCurrentStream(null);
      } else {
        //....cancels the UI• response, •but does not actually cancel the API request, response shows up in the chat history if loaded from server
        ChatAPI.prompt(params.sessionId, "cancel", "", 0, 0, 100, [""], 0, 0, false, true);
      }
      setThinking(false);
      setWaiting(false);
      updateMessage("Request cancelled", params.sessionId, true, props.modelType, true);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Sends our prompt to our API and get response to our request from ai model.
   *
   * @param {Event} e - The submit event of the form.
   */
  const sendMessage = async (e, msg) => {
    e && e.preventDefault();
    let newSession;
    const msgText = msg || formValue;

    if (!msgText.trim() && !selectedFileInfo) {
      console.log("Empty prompt, not sending.");
      return;
    }

    const filter = new Filter();
    const cleanPrompt = filter.isProfane(msgText)
      ? filter.clean(msgText)
      : msgText;

    let newMsg = cleanPrompt;

    if (!params.sessionId) {
      newSession = await createNewSession(newMsg);
    }

    const currentId = params.sessionId || newSession;

    setFormValue('');
    setMessageIndex(null);

    newMsg && updateMessage(newMsg, currentId, false);

    // handle use-case templates, if present and sending initial message
    let templateToSend = template && (messages || []).filter(m => m.sessionId === currentId && m.ai === false).length === 0 ? template : "";

    const stream = (process.env.REACT_APP_STREAM_RESPONSE || "").toLowerCase() === 'true';

    if (props.modelType === 'text') {

      const isQuery = newMsg?.toLowerCase().indexOf('query:') === 0;

      if (isQuery && useCase) {
        submitDbQuery(useCase, newMsg, true);
        return;
      }

      if (stream) {

        // TODO: ..maybe pre-upload on non-streaming requests too?
        if (selectedFileInfo?.file_data) {
          setUploading(true);
          setWaiting(true);
          try {
            const docInfo = await ChatAPI.fileUpload(currentId, selectedFileInfo, false);
            if (docInfo.data.turntype === "image" && !isMobile) {
              updateMessage(JSON.parse(docInfo.data.message), currentId, false, docInfo.data.turntype)
            } 
            else if (docInfo.data.turntype === "image") {
              updateMessage("Image: " + selectedFileInfo.file_name, currentId, false)
            }
            else {
              updateMessage("Document: " + selectedFileInfo.file_name, currentId, false)
            }
            updateMessage('...', currentId, true);
          } catch (error) {
            updateMessage(parseError(error), currentId, true, null, true);
          } finally {
            setUploading(false);
            setWaiting(false);
          }

        } else {
          updateMessage('...', currentId, true);
        }

        try {
          let reply = '';
          const callback = async (event) => {
            // capture & optionally filter out error messages that may be sent as part of the stream
            const jsonMessageRegex = /^\{"message":\s*"[^"]*",\s*"connectionId":\s*"[^"]*",\s*"requestId":\s*"[^"]*"\}$/;

            // capture message end / token usage;
            // {"type": "message_stop", "input_tokens": 404, "output_tokens": 323, "message_id": "<mongo message id>"}
            const jsonStopRegex = /\s*\{(?=.*"type"\s*:\s*"message_stop")(?=[\s\S]*}\s*)/;

            const chunk = event.data;
            if (jsonMessageRegex.test(chunk)) {
              console.error(chunk);
              const data = JSON.parse(chunk);
              if (data.message !== 'Endpoint request timed out') {
                updateMessage("I'm sorry, something went wrong: " + data.message, currentId, true, null, true);
              }
              setWaiting(false);
            } else if (jsonStopRegex.test(chunk)) {
              const stop = JSON.parse(chunk);
              if (stop.message) {
                updateMessage(stop.message, currentId, true, null, true);
              }
              if (stop.message_id) {
                // update message id, to allow setting feedback
                setStreamedMessageStopInfo(stop.message_id, stop.input_tokens, stop.output_tokens);
              }
              //console.log(stop);
              setWaiting(false);
            } else {
              reply += chunk;
              streamMessage(reply);
            }
          };
          // Don't send template if it was just sent as part of file upload
          let streamingResponse = await ChatAPI.promptStream(currentId, newMsg, templateToSend, temperature, topP, topK, maxTokens, stopSequences, ragInfo, isBingSearchEnabled, true, callback);
          setCurrentStream(streamingResponse);
          setWaiting(true);
        } catch (error) {
          console.log(error);
          setThinking(false);
          setWaiting(false);
          updateMessage(parseError(error), currentId, true, true);
        }

      } else {

        setThinking(true);
        setWaiting(true);
        ChatAPI.prompt(currentId, newMsg, templateToSend, temperature, topP, topK, maxTokens, stopSequences, selectedFileInfo, ragInfo, isBingSearchEnabled, false).then(async (response) => {
          const data = response.data;
          setThinking(false);
          setWaiting(false);
          data && updateMessage(data, currentId, true);
        }, (error) => {
          console.log(error);
          setThinking(false);
          setWaiting(false);
          updateMessage(parseError(error), currentId, true, null, true);
        });
      }

    } else {
      setThinking(true);
      setWaiting(true);

      ImageAPI.generate(currentId, newMsg, negativePrompts, height, width, cfgScale, clipGuidancePreset, sampler, samples, seed, steps, stylePreset, aspectRatio, imageStrength, selectedFileInfo, false).then(async (response) => {
        const data = response.data;
        setThinking(false);
        setWaiting(false);

        let newMsgIg = null;
        /**
         * returned data format, pull out message_id for feedback
         {
            "artifacts": [
                [
                    {
                        "seed": 202396450,
                        "base64": "<base64>",
                        "finishReason": "SUCCESS",
                        "image_id": "741780e4-b030-4fac-8f51-7981bc15e8d8",
                        "message_id": "6d5db85c-1d64-46c6-a802-d290f723fe8c-1719256627.694901"
                    }
                ]
            ]
          }
         */

        if (data?.artifacts?.length > 0 && data.artifacts[0].length > 0) {
          newMsgIg = data.artifacts[0][0].message_id;
        }

        data && updateMessage(data, currentId, true, 'image', false, { id: newMsgIg });
      }, (error) => {
        console.log(error);
        setThinking(false);
        setWaiting(false);
        updateMessage(parseError(error), currentId, true, null, true);
      });
    }

    // clear out selected file after upload
    clearSelectedFile();
  };

  const submitDbQuery = async (useCaseQuery, userMessage, isAdHoc = false, display) => {

    let newSession;
    if (!params.sessionId) {
      newSession = await createNewSession(userMessage);
      console.log(newSession)
    }

    const currentId = params.sessionId || newSession;

    if (isAdHoc && !userMessage) {
      updateMessage("To submit a query, please start it with \"query: \" and then describe what information you'd like. I'll take your request and create and run a query and return the results.", currentId, true);
      return;
    }

    setThinking(true);
    setWaiting(true);

    if (isAdHoc) {
      ChatAPI.queryDbAdHoc(currentId, { prompt: userMessage, use_case: useCase.key, use_case_key: useCaseQuery?.key }, false).then(async (response) => {
        const data = response.data;
        setThinking(false);
        setWaiting(false);
        data && updateMessage(data, currentId, true);
      }).catch((error) => {
        console.log(error);
        setThinking(false);
        setWaiting(false);
        updateMessage(parseError(error), currentId, true, null, true);
      });

    } else {

      updateMessage(userMessage, currentId, false);

      const dbQuery = {
        use_case: useCase.key,
        userTurnDescription: userMessage,
        ...useCaseQuery
      };

      ChatAPI.queryDbUseCase(currentId, dbQuery, false).then(async (response) => {
        const message = handleQueryResults(response.data, display);

        setThinking(false);
        setWaiting(false);
        // console.log(data);
        updateMessage(message, currentId, true);

      }, (error) => {
        console.log(error);
        setThinking(false);
        setWaiting(false);
        updateMessage(parseError(error), currentId, true, null, true);
      });
    }

  };

  const handleQueryResults = (responseData, displayConfig) => {
    const summary = responseData?.summary || "";
    let data = responseData?.result;

    if (data) {
      if (displayConfig?.transform) {
        data = displayConfig.transform(data);
      }
      if (data && data.length) {
        data = tablemark(data, displayConfig?.options || {});
      }
    }
    const message = (data ? data + "\n\n" : "") + summary;
    return message;
  }


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage(e);
    }
    if (e.ctrlKey && e.shiftKey && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      const humanMessages = messages.filter(message => !message.ai && message.modelType === props.modelType);
      let newIndex = humanMessages.length;
      if (e.key === 'ArrowUp') {
        if (messageIndex === null || messageIndex < 0) {
          newIndex = humanMessages.length - 1;
        } else if (messageIndex > 0) {
          newIndex = messageIndex - 1;
        } else {
          newIndex = null;
        }

      }
      if (e.key === 'ArrowDown') {
        if (messageIndex !== null && messageIndex < humanMessages.length - 1) {
          newIndex = messageIndex + 1;
        } else if (messageIndex && messageIndex >= humanMessages.length - 1) {
          newIndex = null;
        }
      }

      if (newIndex !== null && newIndex >= 0 && newIndex < humanMessages.length) {
        setMessageIndex(newIndex);
        setFormValue(humanMessages[newIndex].text);
      } else {
        setMessageIndex(null);
        setFormValue('');
      }
    }
  };


  useEffect(() => {
    const regenerateMessage = (message) => {
      let msgIdx = null;
      let priorMessage = null;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].id === message.id) {
          msgIdx = i;
        }
        else if (msgIdx && messages[i].modelType === props.modelType && !messages[i].ai) {
          priorMessage = messages[i];
          setFormValue(priorMessage?.text);
          sendMessage(null, priorMessage?.text);
          break;
        }
      }
    };

    if (messageToResend) {
      regenerateMessage(messageToResend);
      setMessageToResend(null);
    }
  }, [messageToResend]);

  /**
   * Scrolls the chat area to the bottom when the messages array is updated.
   */
  useEffect(() => {
    scrollToBottom();
  }, [messages, thinking]);

  /**
   * Focuses the TextArea input to when the component is first rendered.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  // if loading prior conversation from history, load messages from API
  useEffect(() => {
    if (params.sessionId && messages.filter(message => { return message.modelType === props.modelType && message.sessionId === params.sessionId }).length === 0) {
      ChatAPI.getSession(params.sessionId).then(async (response) => {
        const data = response.data;
        let messages = data.messages;

        messages = messages.map((message) => {
          if (message.kwargs && message.kwargs?.kwargs?.input_tokens && message.kwargs?.kwargs?.output_tokens) {
            message.input_tokens = message.kwargs.kwargs.input_tokens;
            message.output_tokens = message.kwargs.kwargs.output_tokens;
            message.total_tokens = message.input_tokens + message.output_tokens;
          }
          return message;
        });


        // if there are any messages with a use_case key, navigate to the use case 
        // (there can only be one use case per session, so finding it on any message is sufficient)
        let useCaseMessage = (data.messages || []).find(message => message.kwargs?.kwargs?.use_case) || null;
        let sessionUseCase;
        if (useCaseMessage) {
          sessionUseCase = UseCases.find(uc => uc.key === useCaseMessage.kwargs.kwargs.use_case);
          navigate(`/${props.modelType}/usecase/${sessionUseCase.key}/${params.sessionId}`, { replace: true });
        }

        messages.forEach((message) => {
          let m = ((props.modelType === 'image' && message.type === 'assistant') || message.turntype === "image") ? JSON.parse(message.message) : message.message;

          // check if use case has a display transform for this message
          if (message.type === 'assistant' && props.modelType === 'text' && sessionUseCase?.reportTypes) {

            // find the report type that matches the query key
            const reportType = sessionUseCase.reportTypes.reduce((acc, report) => {
              if (acc) {
                return acc;
              }
              if (report.subTypes) {
                return report.subTypes.find(sub => sub.queryKey === message.kwargs?.kwargs?.query_key) || acc;
              } else {
                return report.queryKey === message.kwargs?.kwargs?.query_key ? report : acc;
              }
            }, null);

            if (reportType) {
              const jsonMessageData = JSON.parse(m);
              m = handleQueryResults(jsonMessageData, reportType.display);
            }
          }
          updateMessage(m, params.sessionId, message.type === 'assistant', message.turntype, false, message);
        });

        // set configuration values used for this session
        const lastMessage = data.messages.length > 0 ? data.messages[data.messages.length - 1] : null;
        lastMessage && setConfig(props.modelType, lastMessage.kwargs, "");
      });
    }
  }, [props.modelType, params.sessionId, params.useCaseId, messages, updateMessage, clearMessages, setConfig, navigate, useCase]);

  // if starting new session, reset config settings
  useEffect(() => {
    if (!params.sessionId && !useCase) {
      setConfig(props.modelType, props.modelType === 'text' ? DefaultTextConfig : DefaultImageConfig, "");
    }
  }, [props.modelType, params.sessionId, useCase, setConfig]);

  // If use case specifies an initial message that should come from the bot, create session and display the message.
  // User's initial input will be embedded in the pre-prompt template sent to the model.
  useEffect(() => {
    if (useCase?.template && useCase?.botMessage && !params.sessionId) {
      createNewSession("").then((newSession) => {
        updateMessage(useCase.botMessage, newSession, true)
      });
    }
  }, [useCase, params.sessionId]);

  const chatProps = {
    formValue: formValue,
    setFormValue: setFormValue,
    waiting: waiting,
    creatingSession: creatingSession,
    selectedFileInfo: selectedFileInfo,
    uploading: uploading,
    inputRef: inputRef,
    fileInputRef: fileInputRef,
    handleKeyDown: handleKeyDown,
    handleEnterDropdown: handleEnterDropdown,
    selectFile: selectFile,
    handleFileSelect: handleFileSelect,
    cancelResponse: cancelResponse,
    sendMessage: sendMessage,
    modelType: props.modelType,
    fileTypes: fileTypes,
    clearSelectedFile: clearSelectedFile,
    isBingSearchEnabled: isBingSearchEnabled,
    setIsBingSearchEnabled: setIsBingSearchEnabled,
  };


  const promptMenuProps = {
    initialDropDown: dropdownEnter,
    initialOptions: initialOptions,
    updateFunc: setDropdownEnter,
    updateStateFunc: setFormValue,
  }


  const savePromptModalProps = {
    initialModalPos: savePromptEnter,
    promptContent: saveChatMessage,
    updateFunc: setSavePromptEnter,
    setSaveChatMessage: setSaveChatMessage,
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleModelTypeChange = (newModelType) => {
    navigate(`/${newModelType}`);
    setDropdownOpen(false);
  };

  const uiState = {
    modelType: props.modelType,
    onModelTypeChange: handleModelTypeChange,
    dropdownOpen: dropdownOpen,
    setDropdownOpen: setDropdownOpen,
    ...props.uiState,
  };

  return (
    <div className="chatcontainer w-full">
      {isMobile && <HeaderMobile uiState={uiState} />}
      <div className="chatview">
        {isMobile ? (
          <SelectPromptMobile
            promptMenuProps={promptMenuProps}
          />
        ) :
          (
            <SelectPromptDesktop
              promptMenuProps={promptMenuProps}
            />
          )}

        {isMobile ? (
          <SavePromptMobile
            savePromptModalProps={savePromptModalProps}
          />
        ) : (
          <SavePromptDesktop
            savePromptModalProps={savePromptModalProps}
          />
        )}

        {(useCase || txtSessionTitle || imgSessionTitle) && (
          <div className="chatview__header container bg-white shadow-md text-center text-2xl font-bold py-4" style={{ "maxWidth": "100vw" }}>
            {useCase ? useCase.title : props.modelType === 'text' ? txtSessionTitle : imgSessionTitle}
          </div>
        )}
        {!useCase && (!params.sessionId || messages.filter(message => { return message.modelType === props.modelType && message.sessionId === params.sessionId }).length === 0) ? (
          <GetStarted modelType={props.modelType} key="get-started" />
        ) : (
          <main className='chatview__chatarea'>
            {useCase && (
              <div className="text-lg m-8 p-4 border-solid border-2 border-dark-gray" dangerouslySetInnerHTML={{ __html: useCase.description }}></div>
            )}

            <UseCaseParams submitReportQuery={submitDbQuery} />

            {(() => {
            const uniqueMessageIds = new Set();
            return messages
              .filter(message => message.modelType === props.modelType && message.sessionId === params.sessionId)
              .filter(message => {
                if (!uniqueMessageIds.has(message.id)) {
                  uniqueMessageIds.add(message.id);
                  return true;
                }
                return false;
              })
              .map((message) => (
                <ChatMessage
                  key={message.id}
                  id={message.id}
                  modelType={props.modelType}
                  message={{ ...message }}
                  onRegenerate={() => setMessageToResend(message)}
                  bookmarkPressedFunc={setSavePromptEnter}
                  setSaveChatMessage={setSaveChatMessage}
                />
              ));
            })()}

            {thinking && <Thinking />}

            <span ref={messagesEndRef}></span>
          </main>
        )}
        {/* <SavePromptModal promptContent={"Hello"}></SavePromptModal> */}

      </div>

      {isMobile ? (
        <ChatFormMobile
          chatProps={chatProps}
        />) : (
        <ChatFormDesktop
          chatProps={chatProps}
        />
      )
      }

      <div className="chatview_disclaimer text-center text-s py-4">
        {/* Disclaimer: Lorem Ipsum */}
      </div>
    </div>
  );
};

export default ChatView;